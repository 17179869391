import React, { useState, useEffect } from 'react';
import './css/library.css';
import { Dropdown } from './selecteur';
import imgExpand from "../img/expand.png";
import { Stack, TextField, MenuItem } from '@mui/material';

const Library = () => {
  const [files, setFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedRealCategory, setSelectedRealCategory] = useState('all');
  const [fullscreenFile, setFullscreenFile] = useState(null);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  // Fetch all files from the backend
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/files`);
        if (!response.ok) {
          throw new Error('Failed to fetch files');
        }
        const data = await response.json();
        setFiles(data);
      } catch (err) {
        console.error(err);
        setError('Failed to load files');
      }
    };
    fetchFiles();
  }, []);

  const handleCategoryChange = (value) => setSelectedCategory(value);
  const handleRealCategoryChange = (value) => setSelectedRealCategory(value);
  const handleFileClick = (file, canFullscreen = true) => {
    if (canFullscreen === true) {
      setFullscreenFile(file);
    }
  }
  const handleCloseFullscreen = () => setFullscreenFile(null);

  // Extract unique real categories from files for the filter
  const realCategoryOptions = [
    { label: 'All', value: 'all' },
    ...Array.from(new Set(files.map(file => file.metadata.realCategory)))
      .filter(Boolean) // Filter out undefined or null values
      .map(category => ({ label: category, value: category }))
  ];

  // Category options for language
  const categoryOptions = [
    { label: 'All', value: 'all' },
    { label: 'French', value: 'French' },
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Italian', value: 'Italian' },
  ];

  // Filter files by selected language and real category
  const filteredFiles = files.filter((file) => (
    (selectedCategory === 'all' || file.metadata.category === selectedCategory) &&
    (selectedRealCategory === 'all' || file.metadata.realCategory === selectedRealCategory)
  ));

  // Render files based on their MIME type
  const renderFile = (file, isFullscreen = false) => {
    const originalName = file.metadata.originalName || 'Unknown';
    const filename = file.filename;
    const contentType = file.contentType || 'application/octet-stream';

    if (contentType.startsWith('image/')) {
      const imageUrl = `${apiUrl}/api/image/${encodeURIComponent(filename)}?t=${Date.now()}`;
      return (
        <div className="file-image-wrapper">
          <img src={imageUrl} alt={originalName} className="file-image" />
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </a>
          )}
        </div>
      );
    } else if (contentType === 'application/pdf') {
      const pdfUrl = `${apiUrl}/api/pdf/${encodeURIComponent(filename)}?t=${Date.now()}`;
      return (
        <div className="file-pdf">
          {file.contentType === "application/pdf" && <img className="expandPdf" src={imgExpand} alt="expand" />}
          <iframe src={pdfUrl + '#toolbar=0'} frameBorder="0" title="PDF Viewer" />
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </a>
          )}
        </div>
      );
    } else if (contentType.startsWith('video/')) {
      return (
        <div>
          <video crossOrigin="anonymous" maxWidth="100%" height="320px" controls className="file-video">
            <source src={`${apiUrl}/api/video/${encodeURIComponent(filename)}?t=${Date.now()}`} type={contentType} />
            Your browser does not support the video tag.
          </video>
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </a>
          )}
        </div>
      );
    } else {
      return <div>Unsupported file type</div>;
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="base">
      <div className="library">
        <h1>Library</h1>
        <div className="select-tag">
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
            <TextField
              select
              label="Language"
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ minWidth: 200 }}
            >
              {categoryOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            
            <TextField
              select
              label="Category"
              value={selectedRealCategory}
              onChange={(e) => handleRealCategoryChange(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ minWidth: 200 }}
            >
              {realCategoryOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </div>
        <div className="file-grid">
          {filteredFiles.length > 0 ? (
            filteredFiles.map((file) => (
              <div key={file._id} className="file-item" onClick={() => handleFileClick(file, !file.contentType.startsWith('video/'))}>
                {renderFile(file)}
                <div className="file-name">{file.metadata.name}</div>
                <div className="tags">
                  {file.metadata.tag && (
                    <span className="tag">{file.metadata.tag}</span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No files available</p>
          )}
        </div>
      </div>

      {fullscreenFile && (
        <div className="fullscreen" onClick={handleCloseFullscreen}>
          {fullscreenFile.contentType === 'application/pdf' ? (
            <div className="file-pdf">
              <embed
                src={`${apiUrl}/api/pdf/${encodeURIComponent(fullscreenFile.filename)}?t=${Date.now()}`}
                type="application/pdf"
                width="90%"
                height="80%"
                onError={(e) => console.error("Error loading PDF in fullscreen:", e)}
              />
              <div className="pdf-actions">
                <a
                  href={`${apiUrl}/api/download/${fullscreenFile._id}?download=true`}
                  download={fullscreenFile.metadata.originalName || 'File.pdf'}
                  className="download-btn"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  Download
                </a>
              </div>
            </div>
          ) : (
            renderFile(fullscreenFile, true)
          )}
        </div>
      )}
    </div>
  );
};

export default Library;
