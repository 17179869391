import React, { useState, useEffect } from 'react';
import './css/tips.css';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Tips = () => {
    const [visibleAnswer, setVisibleAnswer] = useState(null);
    const [questions, setQuestions] = useState([]);  // Correct initialisation de l'état

    // Utilisation de useEffect pour les appels API
    useEffect(() => {
        const fetchTips = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/tips`, {
                    withCredentials: true
                });
                setQuestions(response.data.tips);  // Mise à jour de l'état avec les données reçues
            } catch (error) {
                console.error('Error fetching tips:', error);
            }
        };

        fetchTips();
    }, []);  // Le tableau vide [] fait en sorte que l'effet ne soit exécuté qu'une fois

    const toggleAnswer = (index) => {
        setVisibleAnswer(visibleAnswer === index ? null : index);
    };

    return (
        <div className='base'>
            <div className='tips'>
                <div className='title'>
                    <h1>Tips</h1>
                </div>
                <div className='align'>
                    <div className='box-tips'>
                        <h2>Practical Guide to Managing and Increasing Your Score on the Platform 😊</h2>
                        <ul>
                            {questions.map((item, index) => (
                                <li key={index} className='question-item'>
                                    <div className='question' onClick={() => toggleAnswer(index)}>
                                        {item.title}
                                        <button
                                            onClick={() => toggleAnswer(index)}
                                            className={`toggle-button ${visibleAnswer === index ? 'active' : ''}`}
                                        ></button>
                                    </div>
                                    <div className={`answer ${visibleAnswer === index ? 'visible' : ''}`}>
                                        {/* Utilisation de dangerouslySetInnerHTML pour afficher les retours à la ligne */}
                                        <p dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br/>') }}></p>
                                    </div>
                                    <hr />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tips;
