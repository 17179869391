import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/feed.css';
import { Dropdown, DropdownUser } from './selecteur';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Feed = () => {
    const [feedView, setFeedView] = useState('Most Recent');
    const [feedViewInspiration, setFeedViewInspiration] = useState('Most Recent');
    const [posts, setPosts] = useState([]);
    const [users, setUsers] = useState([]); // Declare the users state
    const [selectedUser, setSelectedUser] = useState("all");
    const [selectedCategory, setSelectedCategory] = useState("all");
    const viewMap = {
        'Most Recent': 'newest',
        'Oldest': 'oldest',
        'Most Impressions': 'most-impressions',
        'Least Impressions': 'least-impressions',
        'Most Comments': 'most-comments',
        'Least Comments': 'least-comments',
        'Most Likes': 'most-likes',
        'Least Likes': 'least-likes'
    };
    const viewMapIns = {
        'Most Recent': 'newest',
        'Oldest': 'oldest'
    }

    // à l'initialisation
    useEffect(() => {
        fetchUsers();
    }, []);

    // Quand on change l'utilisateur ou la catégorie ou le tri
    useEffect(() => {
        fetchPosts()
    }, [selectedUser, selectedCategory, feedView, feedViewInspiration]);


    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/users-all`, {
                withCredentials: true
            });
            setUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };

    const fetchPosts = async () => {
        switch (selectedCategory) {
            case "all":
                try {
                    const response = await axios.get(`${apiUrl}/api/posts`, {
                        params: {
                            sort: viewMap[feedView],
                            userId: selectedUser?.id
                        },
                        withCredentials: true  // Si tu gères l'authentification
                    });
                    setPosts(response.data);
                } catch (error) {
                    console.error('Error fetching posts', error);
                }
                break;
            case "inspiration":
                try {
                    const response = await axios.get(`${apiUrl}/api/inspiration`, {
                        params: {
                            sort: viewMap[feedViewInspiration]
                        },
                        withCredentials: true  // Si tu gères l'authentification
                    });
                    setPosts(response.data);
                } catch (error) {
                        console.error('Error fetching posts', error);
                }
                break;
        }
    };

    const handleCategoryChange = (value) => {
        switch (value) {
            case "all":
                setSelectedCategory(value);
                setSelectedUser(null)
                break;
            case "inspiration":
                setSelectedCategory(value);
                setSelectedUser(null)
                break;
            default:
                setSelectedCategory(value);
                setSelectedUser(null)
        }
    };

    const handleFeedViewChange = (event) => {
        switch (selectedCategory) {
            case "all":
                setFeedView(event.target.value);
                break;
            case "inspiration":
                setFeedViewInspiration(event.target.value);
                break;
            default:
                setFeedView(event.target.value);
        }
    };

    return (
        <div className='base'>
            <div className='Feed'>
                <div className='title'>
                    <h1>Feed</h1>
                    <div className='selecter-box' style={{ display: 'flex' }}>
                        {
                            selectedCategory === "all" &&
                            <DropdownUser
                                label="Select a user"
                                options={[
                                    { label: "All Users", value: "all" }, // Option "All" ajoutée au début
                                    ...users.map(user => ({
                                        label: `${user.givenName} ${user.familyName}`,
                                        value: user.id
                                    }))
                                ]}
                                onSelect={(userId) => {
                                    if (userId === "all") {
                                        setSelectedUser(null);
                                    } else {
                                        const user = users.find(user => user.id === userId);
                                        setSelectedUser(user);
                                    }
                                }}
                            />
                        }
                        <Dropdown
                            label="All"
                            options={[
                                { label: 'All', value: 'all' },
                                { label: 'Inspiration', value: 'inspiration' }
                            ]}
                            onSelect={handleCategoryChange} // Passe feedView ici
                        />
                    </div>
                </div>

                <div className='view-selection'>
                    <label htmlFor="feedView">Select the feed view: </label>
                    { selectedCategory === "inspiration" ?
                        <select id="feedView" className='feedView' value={feedViewInspiration} onChange={handleFeedViewChange}>
                            <option value="Most Recent">Most Recent</option>
                            <option value="Oldest">Oldest</option>
                        </select> :
                        <select id="feedView" className='feedView' value={feedView} onChange={handleFeedViewChange}>
                            <option value="Most Recent">Most Recent</option>
                            <option value="Oldest">Oldest</option>
                            <option value="Most Impressions">Most Impressions</option>
                            <option value="Least Impressions">Least Impressions</option>
                            <option value="Most Comments">Most Comments</option>
                            <option value="Least Comments">Least Comments</option>
                            <option value="Most Likes">Most Likes</option>
                            <option value="Least Likes">Least Likes</option>
                        </select>
                    }
                </div>

                { posts.length > 0 ? (
                    <div className='post'>
                        {posts.map((post, index) => (
                            <iframe
                                key={index}
                                src={selectedCategory === "inspiration" ? post.link : post}
                                height="583"
                                width="504"
                                allowFullScreen
                                title="Embedded Post"
                                loading='lazy'
                            />
                        ))}
                    </div>
                ) : (
                    <div className="no-posts">
                        <h5>No posts found for the user</h5>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Feed;
