// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discreteSliderSteps h3 {
    color: black;
    margin: 5px;
    padding: O;
    font-weight: 500;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/css/DiscreteSliderSteps.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".discreteSliderSteps h3 {\r\n    color: black;\r\n    margin: 5px;\r\n    padding: O;\r\n    font-weight: 500;\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
