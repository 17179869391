// src/index.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Importer le composant App
import './index.css'; // Importer les styles globaux
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  </React.StrictMode>,
  document.getElementById('root') // Rendre dans l'élément avec l'id 'root'
);
