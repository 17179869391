import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import './css/DiscreteSliderSteps.css';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function valuetext(value) {
    return `${value}%`;
}

function SliderSteps({ type, value, onChange }) {
    return (
        <Box sx={{ width: 300 }}>
            <Slider
                aria-label="Small steps"
                value={value}
                onChange={(e, newValue) => onChange(type, newValue)}
                getAriaValueText={valuetext}
                step={10}
                marks
                min={0}
                max={100}
                valueLabelDisplay="auto"
            />
        </Box>
    );
}

function DiscreteSliderSteps() {
    const [settings, setSettings] = useState({ Comment: 0, Like: 0 }); // Initial state for sliders
    const [loading, setLoading] = useState(true);

    // Fetch admin settings when component mounts
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/admin-settings`);
                const fetchedSettings = response.data.reduce((acc, setting) => {
                    acc[setting.type] = setting.pourcent;
                    return acc;
                }, {});
                setSettings(fetchedSettings);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching admin settings:', error);
            }
        };

        fetchSettings();
    }, []);

    // Handle slider value change
    const handleSliderChange = async (type, newValue) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [type]: newValue
        }));

        try {
            await axios.put(`${apiUrl}/api/admin-settings`, { type, pourcent: newValue });
        } catch (error) {
            console.error('Error updating admin setting:', error);
        }
    };

    return (
        <div className='box discreteSliderSteps'>
            {loading ? (
                <p>Loading settings...</p>
            ) : (
                <>
                    <h3>Comment</h3>
                    <SliderSteps type="Comment" value={settings.Comment} onChange={handleSliderChange} />
                    <h3>Like</h3>
                    <SliderSteps type="Like" value={settings.Like} onChange={handleSliderChange} />
                </>
            )}
        </div>
    );
}

export default DiscreteSliderSteps;
