import React, { useState, useEffect } from "react";
import "./css/ToggleSwitch.css";
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const ToggleSwitch = () => {
    const [isToggled1, setIsToggled1] = useState(false); // For "like" toggle
    const [isToggled2, setIsToggled2] = useState(false); // For "comment" toggle
    const [loading, setLoading] = useState(true); // To handle loading state

    // Helper function to get cookies by name
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    // Fetch user's current settings for like and comment when the component mounts
    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/userStatus`, {
                    method: "GET",
                    credentials: "include",  // This ensures cookies are sent with the request
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setIsToggled1(data.like);  // Set the initial value for "like"
                setIsToggled2(data.comment);  // Set the initial value for "comment"
                setLoading(false);  // Stop loading once data is fetched
            } catch (error) {
                console.error("Error fetching user settings:", error);
                setLoading(false);  // Stop loading on error
            }
        };

        fetchUserSettings();
    }, []);

    // Handle toggling the "like" switch
    const toggleSwitch1 = async () => {
        const newStatus = !isToggled1;
        setIsToggled1(newStatus);

        try {
            const response = await fetch(`${apiUrl}/api/user/like`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",  // Ensure the cookies are sent
                body: JSON.stringify({ likeStatus: newStatus }), // No need to send userId, it's in the cookie
            });

            if (!response.ok) {
                throw new Error('Failed to update like status');
            }
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    // Handle toggling the "comment" switch
    const toggleSwitch2 = async () => {
        const newStatus = !isToggled2;
        setIsToggled2(newStatus);

        try {
            const response = await fetch(`${apiUrl}/api/user/comment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",  // Ensure the cookies are sent
                body: JSON.stringify({ commentStatus: newStatus }), // No need to send userId, it's in the cookie
            });

            if (!response.ok) {
                throw new Error('Failed to update comment status');
            }
        } catch (error) {
            console.error("Error updating comment status:", error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;  // Display loading state while fetching data
    }

    return (
        <div className="box ToggleSwitch">
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    id="switch1"
                    checked={isToggled1}
                    onChange={toggleSwitch1}
                />
                <label htmlFor="switch1"></label>
                <p>Activate or deactivate Like</p>
            </div>

            <div className="toggle-switch">
                <input
                    type="checkbox"
                    id="switch2"
                    checked={isToggled2}
                    onChange={toggleSwitch2}
                />
                <label htmlFor="switch2"></label>
                <p>Activate or deactivate Comment</p>
            </div>
        </div>
    );
};

export default ToggleSwitch;
