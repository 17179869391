import React, { useState, useEffect } from 'react';
import './css/FeedList.css'; // Ensure the CSS file is linked
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Tips = () => {
    const [tips, setTips] = useState([]);  // Initialize tips
    const [selectedTip, setSelectedTip] = useState(null);  // Selected tip for preview and editing
    const [checkedTips, setCheckedTips] = useState([]);  // Tips selected for deletion
    const [isEditing, setIsEditing] = useState(false);  // Editing state
    const [isAdding, setIsAdding] = useState(false);  // Adding state

    // Fetch tips from the API
    const fetchTips = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/tips`);
            const data = await response.json();
            setTips(data.tips);  // Update the state with the fetched tips
        } catch (error) {
            console.error('Error fetching tips:', error);
        }
    };

    // Add a new tip
    const addTip = async (e) => {
        e.preventDefault();
        const newTitle = e.target.title.value;
        const newDescription = e.target.description.value;
        if (newTitle && newDescription) {
            try {
                const response = await fetch(`${apiUrl}/api/tips`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ title: newTitle, description: newDescription })
                });
                const data = await response.json();
                setTips([...tips, data.tips]);  // Add the new tip to the list
                setIsAdding(false);  // Close the add dialog
            } catch (error) {
                console.error('Error adding tip:', error);
            }
        }
    };

    // Edit the selected tip
    const editTip = (tip) => {
        setSelectedTip(tip);
        setIsEditing(true);
    };

    // Update the selected tip
    const updateTip = async (e) => {
        e.preventDefault();
        const updatedTitle = e.target.title.value;
        const updatedDescription = e.target.description.value;

        try {
            await fetch(`${apiUrl}/api/tips`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedTip._id, title: updatedTitle, description: updatedDescription })
            });

            // Update the tips in the frontend
            setTips(tips.map((tip) => (tip._id === selectedTip._id ? { ...tip, title: updatedTitle, description: updatedDescription } : tip)));

            setSelectedTip(null);  // Deselect the tip after editing
            setIsEditing(false);  // Exit editing mode
        } catch (error) {
            console.error('Error updating tip:', error);
        }
    };

    // Delete selected tips
    const removeTips = async () => {
        try {
            for (let id of checkedTips) {
                await fetch(`${apiUrl}/api/tips/${id}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' }
                });
            }
            setTips(tips.filter(tip => !checkedTips.includes(tip._id)));  // Remove the deleted tips from the state
            setCheckedTips([]);  // Clear the selected tips
        } catch (error) {
            console.error('Error removing tips:', error);
        }
    };

    // Toggle tip selection for deletion
    const toggleCheck = (id) => {
        const newCheckedTips = checkedTips.includes(id)
            ? checkedTips.filter(tipId => tipId !== id)
            : [...checkedTips, id];
        setCheckedTips(newCheckedTips);
    };

    // Select a tip for preview or editing
    const handleTipSelection = (tip) => {
        if (selectedTip === tip) {
            setSelectedTip(null);  // Deselect if the same tip is clicked
        } else {
            setSelectedTip(tip);
        }
    };

    // Load the tips when the component mounts
    useEffect(() => {
        fetchTips();
    }, []);

    return (
        <div className='feedList'>
            <div className="toolbar">
                <button onClick={removeTips} className="delete-button" disabled={checkedTips.length === 0}>🗑️</button>
                <button onClick={() => setIsAdding(true)} className="add-button">+</button>
            </div>
            <ul>
                {tips.map((tip) => (
                    <li key={tip._id} className={selectedTip === tip ? 'selected' : ''}>
                        <input
                            type="checkbox"
                            checked={checkedTips.includes(tip._id)}
                            onChange={() => toggleCheck(tip._id)}
                        />
                        <span onClick={() => handleTipSelection(tip)}>{tip.title}</span>
                        {selectedTip === tip && !isEditing && (
                            <div className="preview">
                                <p>{tip.description}</p>
                                <button onClick={() => editTip(tip)} className="edit-button">edit</button>
                            </div>
                        )}
                    </li>
                ))}
            </ul>

            {/* Dialog for editing */}
            <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
                <form onSubmit={updateTip}>
                    <DialogTitle>Edit Tip</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Title"
                            type="text"
                            fullWidth
                            defaultValue={selectedTip?.title || ''}
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                            defaultValue={selectedTip?.description || ''}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsEditing(false)} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="contained">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* Dialog for adding */}
            <Dialog open={isAdding} onClose={() => setIsAdding(false)}>
                <form onSubmit={addTip}>
                    <DialogTitle>Add New Tip</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="title"
                            label="Title"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description"
                            type="text"
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAdding(false)} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="contained">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default Tips;
