import { useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const useUserInfo = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/user-info`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }

                const data = await response.json();
                setUserInfo(data.user);
                console.log('User info:', data.user);
            } catch (error) {
                console.error('Error fetching user info:', error);
                setError('Failed to load user information');
            }
        };

        fetchUserInfo();
    }, []);

    return { userInfo, error };  // Ensure this returns an object
};

export default useUserInfo;