// components/Library.js

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,Typography,
    Card,
    CardContent,
    CardMedia,
    Stack, 
    Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './css/library.css';
import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe-click';

const Library = () => {
    const [selectedCategory, setSelectedCategory] = useState('French');
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [tag, setTag] = useState('');
    const [newName, setNewName] = useState('');
    const [newRealCategory, setNewRealCategory] = useState('');
    const [errors, setErrors] = useState({
        name: false,
        category: false,
        file: false,
    });

    const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'; // Ensure this is your backend URL

    useEffect(() => {
        loadFiles(selectedCategory);
    }, [selectedCategory]);
    
        const loadFiles = async (category) => {
            try {
                let url = `${apiUrl}/api/files`;

                // If a category is specified and it's different from 'all', add the filter
                if (category && category !== 'all') {
                    url = `${apiUrl}/api/files/${encodeURIComponent(category)}`;
                }

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Error loading files: ${response.statusText}`);
                }
                const data = await response.json();
                if (Array.isArray(data)) {
                    setFiles(data);
                } else {
                    console.warn("The received data is not an array:", data);
                    setFiles([]);
                }
            } catch (error) {
                console.error('Error loading files:', error);
                setFiles([]);
            }
        };

        const handleCategoryChange = (category) => {
            console.log("Category changed:", category); // For debugging
            setSelectedCategory(category);
            setSelectedFile(null); // Reset the selected file when category changes
        };

        const handleFileSelect = (file) => {
            console.log("File selected:", file); // For debugging
            setSelectedFile(file);
        };

        const handleFileUpload = async () => {
            setErrors({ name: false, category: false, file: false });
        
            let valid = true;
        
            if (!newName) {
                setErrors((prev) => ({ ...prev, name: true }));
                valid = false;
            }
            if (!newRealCategory) {
                setErrors((prev) => ({ ...prev, category: true }));
                valid = false;
            }
            if (!fileToUpload) {
                setErrors((prev) => ({ ...prev, file: true }));
                valid = false;
            }
            
            if (valid) {
                console.log("Sending data");
        
                // Initialiser FormData
                const formData = new FormData();
        
                // Ajouter les champs à formData
                formData.append('file', fileToUpload);
                formData.append('tag', tag);
                formData.append('realCategory', newRealCategory);
                formData.append('name', newName);
        
                try {
                    const response = await fetch(`${apiUrl}/api/files/${encodeURIComponent(selectedCategory)}`, {
                        method: 'POST',
                        body: formData,
                    });
        
                    if (response.ok) {
                        console.log("File uploaded successfully");
                        await loadFiles(selectedCategory);
                        setShowModal(false);
                        setFileToUpload(null);
                        setTag('');
                        setNewName(''); // Réinitialiser le champ 'name'
                        setNewRealCategory(''); // Réinitialiser le champ 'realCategory'
                    } else {
                        const errorText = await response.text();
                        console.error('Error uploading file:', response.statusText, errorText);
                        alert('Error uploading the file. Please check the console for details.');
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                    alert('Error uploading the file. Please check the console for details.');
                }
            }
        };
        

    const handleFileDelete = async () => {
        if (!selectedFile) {
            alert('Please select a file to delete!');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/files/${selectedFile._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                console.log("File deleted successfully"); // For debugging
                await loadFiles(selectedCategory);
                setSelectedFile(null);
            } else {
                const errorText = await response.text();
                console.error('Error deleting file:', response.statusText, errorText);
                alert('Error deleting the file. Please check the console for details.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Error deleting the file. Please check the console for details.');
        }
    };

    const getFileUrl = (file) => {
        const originalName = file.metadata.originalName || '';
        const lowerCaseName = originalName.toLowerCase();

        if (lowerCaseName.endsWith('.pdf')) {
            return `${apiUrl}/api/pdf/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else if (
            lowerCaseName.endsWith('.jpg') ||
            lowerCaseName.endsWith('.jpeg') ||
            lowerCaseName.endsWith('.png') ||
            lowerCaseName.endsWith('.gif')
        ) {
            return `${apiUrl}/api/image/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else if (lowerCaseName.endsWith('.mp4')) {
            return `${apiUrl}/api/video/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else {
            return `${apiUrl}/api/download/${file._id}`;
        }
    };

    // File rendering function, similar to FileList.js
    const renderFile = (file, previewMode) => {
        const originalName = file.metadata.originalName || 'Unknown';
        const lowerCaseName = originalName.toLowerCase();
        const fileUrl = getFileUrl(file);

        if (lowerCaseName.endsWith('.pdf')) {
            return (
                <div className="file-pdf">
                    <Iframe
                        style={previewMode? {width:"100%", height:"430px", cursor:"pointer"}: {width:"100px", maxHeight:"110px", cursor:"pointer"}}
                        src={fileUrl}
                        onInferredClick={() => setSelectedFile(file)}
                    ></Iframe>
                </div>
            );
        } else if (lowerCaseName.endsWith('.mp4')) {
            return (
                <video crossOrigin={"anonymous"} style={previewMode ? { maxWidth: '100%', height: '100%' } : { maxWidth: '200px', height: '100px', cursor:'pointer' }} controls={previewMode}>
                    <source src={fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (
            lowerCaseName.endsWith('.jpg') ||
            lowerCaseName.endsWith('.jpeg') ||
            lowerCaseName.endsWith('.png') ||
            lowerCaseName.endsWith('.gif')
        ) {
            return (
                <img
                    src={fileUrl}
                    alt={originalName}
                    style={{ maxWidth: '100%', maxHeight: '100%', cursor:"pointer" }}
                    onError={(e) => console.error("Error loading image:", e)}
                />
            );
        } else {
            return (
                <div className="file-download">
                    <a href={fileUrl} download={originalName || 'File'} className="download-link">
                        Download
                    </a>
                </div>
            );
        }
    };

    return (
        <div className="box">
            <Grid className='w-100 d-flex justify-between' sx={{justifyContent: "space-between",alignItems: "center",}}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<AddIcon />} 
                    onClick={() => setShowModal(true)}
                >
                    Add File
                </Button>
                {
                    selectedFile &&
                        <Button 
                            variant="outlined" 
                            color="error" 
                            startIcon={<DeleteIcon />} 
                            onClick={handleFileDelete} 
                            disabled={!selectedFile}
                        >
                            Delete
                        </Button>
                }
            </Grid>
            <div className="library-s">
                {/* Sidebar for categories */}
                <div className="sidebar-ly">
                    {['French', 'English', 'Spanish', 'Italian'].map((category, index) => (
                        <div
                            key={index}
                            className={`folder ${selectedCategory === category ? 'active' : ''}`}
                            onClick={() => handleCategoryChange(category)}
                        >
                            {category}
                        </div>
                    ))}
                </div>

                {/* File view */}
                <div className="file-view">
                    {Array.isArray(files) && files.length > 0 ? (
                        <div className="file-grid">
                            {files.map((file) => {
                                const originalName = file.metadata.originalName || '';
                                const lowerCaseName = originalName.toLowerCase();
                                const fileUrl = getFileUrl(file);

                                return (
                                    <div
                                        key={file._id}
                                        className={`file-item ${selectedFile && selectedFile._id === file._id ? 'selected' : ''}`}
                                        onClick={() => handleFileSelect(file)}
                                    >
                                        {renderFile(file)}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p>No files available</p>
                    )}
                </div>

                {/* Preview and controls */}
                <div className="contain-preview">
                    <div className="file-preview">
                        {selectedFile ? (
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia>
                                    {renderFile(selectedFile, true)}
                                </CardMedia>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {selectedFile.metadata.originalName || 'No name'}
                                    </Typography>
                                    <Stack spacing={1}>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Nom:</strong> {selectedFile.metadata.name || 'Aucun nom'}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Catégorie:</strong> {selectedFile.metadata.realCategory || 'Aucune catégorie'}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Tag:</strong> {selectedFile.metadata.tag || 'Aucun tag'}
                                        </Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        ) : (
                            <Typography variant="body2" color="text.secondary">Aucun fichier sélectionné</Typography>
                        )}
                    </div>
                </div>
            </div>

            {/* Upload Modal */}
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
            <DialogTitle>Upload a File</DialogTitle>
            <DialogContent>
                <input
                    type="file"
                    name="file"
                    onChange={(e) => setFileToUpload(e.target.files[0])}
                    style={{ marginBottom: '16px' }}
                />
                {errors.file && <span style={{ color: 'red' }}>File is required.</span>}
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    required
                    error={errors.name}
                    helperText={errors.name ? 'Name is required.' : ''}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    label="Category"
                    variant="outlined"
                    fullWidth
                    value={newRealCategory}
                    onChange={(e) => setNewRealCategory(e.target.value)}
                    required
                    error={errors.category}
                    helperText={errors.category ? 'Category is required.' : ''}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    label="Tag (optional)"
                    variant="outlined"
                    fullWidth
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    style={{ marginBottom: '16px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleFileUpload} variant="contained" color="primary">
                    Upload
                </Button>
                <Button onClick={() => setShowModal(false)} variant="outlined" color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
};

export default Library;