import React from 'react';
import './css/awards.css'; // Tes styles personnalisés si nécessaire
import First from "../img/1stplace.png"
import Second from "../img/2ndplace.png"
import Third from "../img/3rdplace.png"

const Awards = () => {
    const monthlyAwards = [
        { position: 'First', price: 'Trip for 2 to Morocco', image: First },
        { position: 'Second', price: '750 €', image: Second },
        { position: 'Third', price: '500 €', image: Third },
        { position: 'Fourth', price: '250€' },
        { position: 'Fifth', price: '250 €' },
    ];

    const exceptionalAwards = [
        { impressions: '500 000 impressions / month', price: '5 000 €' },
        { impressions: '1 000 000 impressions / month', price: '10 000 €' },
    ];

    const positionImages = {
        First: First,
        Second: Second,
        Third: Third
    };

    const previousWinners = {
        August: [
            { name: "Jeremy BROCHARD", position: "First", prize: "Trip to Morocco" },
            { name: "Nicole BERGERE", position: "Second", prize: "500€" },
            { name: "Sophie DEBOISEE", position: "Third", prize: "250€" }
        ],
        July: [
            { name: "Paul MARTIN", position: "First", prize: "Trip to Bali" },
            { name: "Anna DUPONT", position: "Second", prize: "700€" },
            { name: "Lucie LEBRUN", position: "Third", prize: "300€" }
        ]
    };

    return (
        <div className="base">
            <div className="container">
                <h1 className="text-center my-5">🏅AWARDS🏅</h1>

                <section className={"text-center"}>
                    <h2 className="text-center mb-4">PRICE OF OCTOBER</h2>
                    <div className="row text-center">
                        <div className="col-md-12 mb-3">
                            <div className="card p-4">
                                <img src={First} alt="First place" className="img-fluid mx-auto" style={{ width: '7em' }} />
                                <p className="mt-3">Trip for 2 to Morocco</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {monthlyAwards.slice(1).map((award, index) => (
                            <div key={index} className="col-md-6 mb-4">
                                <div className="card p-4">
                                    {award.image ? (
                                        <img src={award.image} alt={`${award.position} place`} className="img-fluid mx-auto" style={{ width: '5em' }} />
                                    ) : <h5>{award.position}</h5>}
                                    <p className="mt-3">{award.price}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row text-center">
                        <h2 className="text-center my-4">EXCLUSIVE PRICE</h2>
                        {exceptionalAwards.map((award, index) => (
                            <div key={index} className="col-md-6 mb-4">
                                <div className="card p-4" style={{background:"rgba(255,242,191,0.61)"}}>
                                    <h4>{award.impressions}</h4>
                                    <hr />
                                    <p>{award.price}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <div className="my-5 text-center">
                    <hr className="w-50 mx-auto" />
                </div>

                <section>
                    <h2 className="text-center mb-4">PREVIOUS WINNERS</h2>
                    {Object.keys(previousWinners).map((month, index) => (
                        <div key={index} className="mb-5">
                            <h3>{month}</h3>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th className="text-end">Prize</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {previousWinners[month].map((winner, idx) => (
                                        <tr key={idx}>
                                            <td className="text-center" style={{width:"7em"}}>
                                                <img
                                                    src={positionImages[winner.position]}
                                                    alt={`${winner.position} place`}
                                                    style={{ width: '25px' }}
                                                />
                                            </td>
                                            <td>{winner.name}</td>
                                            <td className="text-end">{winner.prize}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
};

export default Awards;
