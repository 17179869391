// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedList ul {
    list-style: none;
    padding: 0;
    font-weight: 500;
    width: 650px;
}

.feedList li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 0.5px solid #D9D9D9;
    font-weight: 500;
}

.feedList li:hover {
    color: #0177FD;
}

.feedList input[type="checkbox"] {
    margin-right: 10px;
}

.feedList button {
    margin-left: 10px;
}

.feedList .toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.feedList .preview {
    margin-top: 10px;
    background: #f0f0f0;
    padding: 10px;
    border: 1px solid #ccc;
}

.feedList iframe {
    width: 100%;
    height: 300px;
    border: none;
}

.feedList .selected {
    background-color: #e8f0fe;
}

.feedList {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
    margin: 25px;
}

.feedList .add-button {
    border: none;
    background-color: white;
    font-size: 25px;
}

.feedList .delete-button {
    border: none;
    background-color: white;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/css/FeedList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;IAClB,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".feedList ul {\r\n    list-style: none;\r\n    padding: 0;\r\n    font-weight: 500;\r\n    width: 650px;\r\n}\r\n\r\n.feedList li {\r\n    margin-bottom: 10px;\r\n    padding-bottom: 10px;\r\n    position: relative;\r\n    border-bottom: 0.5px solid #D9D9D9;\r\n    font-weight: 500;\r\n}\r\n\r\n.feedList li:hover {\r\n    color: #0177FD;\r\n}\r\n\r\n.feedList input[type=\"checkbox\"] {\r\n    margin-right: 10px;\r\n}\r\n\r\n.feedList button {\r\n    margin-left: 10px;\r\n}\r\n\r\n.feedList .toolbar {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.feedList .preview {\r\n    margin-top: 10px;\r\n    background: #f0f0f0;\r\n    padding: 10px;\r\n    border: 1px solid #ccc;\r\n}\r\n\r\n.feedList iframe {\r\n    width: 100%;\r\n    height: 300px;\r\n    border: none;\r\n}\r\n\r\n.feedList .selected {\r\n    background-color: #e8f0fe;\r\n}\r\n\r\n.feedList {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 5px;\r\n    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);\r\n    margin: 25px;\r\n}\r\n\r\n.feedList .add-button {\r\n    border: none;\r\n    background-color: white;\r\n    font-size: 25px;\r\n}\r\n\r\n.feedList .delete-button {\r\n    border: none;\r\n    background-color: white;\r\n    font-size: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
